export const STRICTLY_US_STATES = {
  DE: 'Delaware',
  CA: 'California',
  TX: 'Texas',
  NY: 'New York',
  FL: 'Florida',
  WA: 'Washington',
  IL: 'Illinois',
  PA: 'Pennsylvania',
  OH: 'Ohio',
  GA: 'Georgia',
  NC: 'North Carolina',
  MI: 'Michigan',
  NJ: 'New Jersey',
  VA: 'Virginia',
  AZ: 'Arizona',
  MA: 'Massachusetts',
  TN: 'Tennessee',
  IN: 'Indiana',
  MO: 'Missouri',
  MD: 'Maryland',
  WI: 'Wisconsin',
  CO: 'Colorado',
  MN: 'Minnesota',
  SC: 'South Carolina',
  AL: 'Alabama',
  LA: 'Louisiana',
  KY: 'Kentucky',
  OR: 'Oregon',
  OK: 'Oklahoma',
  CT: 'Connecticut',
  UT: 'Utah',
  IA: 'Iowa',
  NV: 'Nevada',
  AR: 'Arkansas',
  MS: 'Mississippi',
  KS: 'Kansas',
  NM: 'New Mexico',
  NE: 'Nebraska',
  WV: 'West Virginia',
  ID: 'Idaho',
  HI: 'Hawaii',
  NH: 'New Hampshire',
  ME: 'Maine',
  MT: 'Montana',
  RI: 'Rhode Island',
  SD: 'South Dakota',
  ND: 'North Dakota',
  AK: 'Alaska',
  DC: 'District of Columbia',
  VT: 'Vermont',
  WY: 'Wyoming',
  PR: 'Puerto Rico',
};

export const US_STATES = {
  DE: 'Delaware',
  CA: 'California',
  TX: 'Texas',
  NY: 'New York',
  FL: 'Florida',
  WA: 'Washington',
  IL: 'Illinois',
  PA: 'Pennsylvania',
  OH: 'Ohio',
  GA: 'Georgia',
  NC: 'North Carolina',
  MI: 'Michigan',
  NJ: 'New Jersey',
  VA: 'Virginia',
  AZ: 'Arizona',
  MA: 'Massachusetts',
  TN: 'Tennessee',
  IN: 'Indiana',
  MO: 'Missouri',
  MD: 'Maryland',
  WI: 'Wisconsin',
  CO: 'Colorado',
  MN: 'Minnesota',
  SC: 'South Carolina',
  AL: 'Alabama',
  LA: 'Louisiana',
  KY: 'Kentucky',
  OR: 'Oregon',
  OK: 'Oklahoma',
  CT: 'Connecticut',
  UT: 'Utah',
  IA: 'Iowa',
  NV: 'Nevada',
  AR: 'Arkansas',
  MS: 'Mississippi',
  KS: 'Kansas',
  NM: 'New Mexico',
  NE: 'Nebraska',
  WV: 'West Virginia',
  ID: 'Idaho',
  HI: 'Hawaii',
  NH: 'New Hampshire',
  ME: 'Maine',
  MT: 'Montana',
  RI: 'Rhode Island',
  SD: 'South Dakota',
  ND: 'North Dakota',
  AK: 'Alaska',
  DC: 'District of Columbia',
  VT: 'Vermont',
  WY: 'Wyoming',
  PR: 'Puerto Rico',
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon Territory',
  OTHER: 'Other / International',
};

export const CA_STATES = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon Territory',
};

export const STATES = {
  US: US_STATES,
  CA: CA_STATES,
};

export const getUSStateOptions = (stateNameAsValue = false) => {
  const countryStates = STRICTLY_US_STATES;
  if (countryStates) {
    return Object.keys(countryStates).map((stateId) => ({
      value: stateNameAsValue ? countryStates[stateId] : stateId,
      label: countryStates[stateId],
    }));
  }
  return [];
};

// this will return [{ value: 'IL', label: 'Illinois' }]
export const getStateOptions = (country) => {
  const countryStates = STATES[country];
  if (countryStates) {
    return Object.keys(countryStates).map((stateId) => ({
      value: stateId,
      label: countryStates[stateId],
    }));
  }
  return [];
};

// this will return [{ value: 'Illinois', label: 'Illinois' }]
export const getStateNameOptions = (country) => {
  const countryStates = STATES[country];
  if (countryStates) {
    return Object.values(countryStates).map((stateName) => ({
      value: stateName,
      label: stateName,
    }));
  }
  return [];
};

export const getStateNameByCode = (country, stateCode) => {
  const countryStates = STATES[country];
  if (countryStates) {
    return countryStates[stateCode];
  }
  return stateCode;
};

export const getCodeByStateName = (country, stateName) => {
  const countryStates = STATES[country];
  if (countryStates && stateName) {
    return Object.keys(countryStates).find(
      (stateCode) =>
        countryStates[stateCode].toUpperCase() === stateName.toUpperCase() ||
        stateName.toUpperCase() === stateCode.toUpperCase(),
    );
  }
  return stateName;
};
